import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const NecessaryItem1 = ({title, text, isShow=true}) => {
    return (
        <div style={{display: isShow ? 'block' : 'none', opacity: isShow ? 1 : 0}} className="necessary">
            <div className="necessary-image">
                <StaticImage className="image" src={'../../../assets/images/royal_tulip.png'} alt=""/>
            </div>
            <div  className="necessary-text--wrapper">
                <div className="necessary-title">{title}</div>
                <span className="necessary-text">{text}</span>
            </div>
        </div>
    )
}

export default NecessaryItem1;