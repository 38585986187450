import React from "react";

const Sandwich = ({setIsOpen, isOpen}) => {
    return (
        <div onClick={() => setIsOpen(prev => !prev)} className={`sandwich ${isOpen ? "is-active" : ""}`}>
            <div className="sandwich__line sandwich__line--top"/>
            <div className="sandwich__line sandwich__line--middle"/>
            <div className="sandwich__line sandwich__line--bottom"/>
        </div>
    )
}

export default Sandwich;