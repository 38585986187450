import React from "react";
import {Text} from "../../atoms";
import { StaticImage } from "gatsby-plugin-image";

const WorkerSlide3 = ({index, title, text, post}) => {
    return (
        <div className="worker--wrapper">
            <div className="worker-image">
                <StaticImage className="image" src={'../../../assets/images/pracownik3.jpg'} alt=""/>
            </div>
            <div className="worker-info">
                <div className="worker-text--wrapper">
                    <div className="worker-count">
                        <Text text={`${index}/3`}/>
                    </div>
                    <div className="worker-title">
                        <Text text={title} />
                    </div>
                    <div className="worker-text">
                        <Text text={text} />
                    </div>
                </div>


                <div className="worker-post--wrapper">
                    <div className="worker-post">
                        <Text text={post} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WorkerSlide3;