import React from "react";
import {Link} from "gatsby";

const Logo = ({isWhite=false}) => {
    return (
        <Link className={"link"} to={'/'}>
            <div className="logo--wrapper">
                <img className="image logo" src={require(`../../../assets/images/${isWhite ? 'logo-white.svg' : 'logo.png'}`).default} alt="KomandaPL logo"/>
            </div>
        </Link>

    )
}

export default Logo;