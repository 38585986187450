import React from "react";

const RightArrow = ({onClick}) => {
    return (
        <div className="slider-arrow slider-arrow--right" onClick={onClick}>
            <img className="image" src={require('../../../assets/icons/arrow-right.svg').default} alt=""/>
        </div>
    )
}

export default RightArrow;