import React from "react";
import {FooterTop, FooterBottom} from "../../organisms";

const Footer = ({isShow=true}) => {
    return (
        <div  className="footer--block">
            <FooterTop isShow={isShow}/>
            <FooterBottom />
        </div>
    )
}
export default Footer;