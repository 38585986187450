import React from "react";
import {Heading} from "../../atoms";
import {Condition} from "../../molecules";
import {useLang} from "../../../helpers/context/LanguageProvider";


const OurConditions = () => {
    const {language, translate} = useLang();
    return (
        <div className="container">
            <div className="condition-title">
                <Heading text={translate.mainPage.conditions[language.name]} />
            </div>
             <div className="condition--wrapper">
                 <Condition text={translate.benefits.items.employment[language.name]} icon={"💼"} />
                 <Condition text={translate.benefits.items.freeHousing[language.name]} icon={"🛏️"} />
                 <Condition text={translate.benefits.items.payments[language.name]} icon={"💰"} />
                 <Condition text={translate.benefits.items.support[language.name]} icon={"👌"} />
                 <Condition text={translate.benefits.items.growth[language.name]} icon={"📈"} />
                 <Condition text={translate.benefits.items.docs[language.name]} icon={"🌎"} />
             </div>
        </div>
    )
}

export default OurConditions;
// translate.benefits.items.employment[language.name]
// translate.benefits.items.freeHousing[language.name]
// translate.benefits.items.payments[language.name]

// translate.benefits.items.support[language.name]
// translate.benefits.items.growth[language.name]
// translate.benefits.items.docs[language.name]