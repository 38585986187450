import React from "react";
import {AboutButton} from "../../atoms";
import { StaticImage } from "gatsby-plugin-image";

const AboutBlock = ({title, texts, image, alt = "", isButton = false, buttonText}) => {

    return (
        <div className="container">
            <div className="about-title-text">
                {title}
            </div>
            <div className="about">

                <div className="about-text--wrapper">
                    {title ?
                        <div className="about-title">
                            {title}
                        </div>
                        : <></>}
                        <div className="about-text--block">
                            {texts?.map((text) => (
                                <div key={text} className="about-text">{text}</div>
                            ))}
                        </div>
                    {isButton ?
                        <AboutButton buttonText={buttonText} />
                        : <></>}
                </div>
                <div className="about-image">
                    <StaticImage className="image" src={'../../../assets/images/building1.png'} alt={alt} />
                </div>
            </div>
        </div>
    )
}

export default AboutBlock;