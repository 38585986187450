import React from "react";
import {Link} from "gatsby";

const AboutButton = ({buttonText}) => {
    return (
        <Link className="link" to={'/about'}>
            <div className="about-button">
                {buttonText}
            </div>
        </Link>

    )
}

export default AboutButton;