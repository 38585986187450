import React from "react";

const Condition = ({text, icon}) => {
    return (
        <div className="condition">
            <div className={`condition-icon`}>
                {icon}
            </div>
            <div className="condition-text">{text}</div>
        </div>
    )
}

export default Condition;