import React from "react";
import {Heading, Text, VacancyButton} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image"


const MainBanner = () => {
    const {language, translate} = useLang();

    return (
        <div className="main-banner--wrapper">
            <div className="main-banner-text--block">
                <div className="main-banner-text--wrapper">
                    <div className="main-banner-text-title">
                        <Heading text={`${translate.mainPage.banner.title[language.name]} \uD83C\uDDF5\uD83C\uDDF1`} />
                    </div>
                    <div className="main-banner-text">
                        <Text text={translate.mainPage.banner.text[language.name]} />
                    </div>
                    <VacancyButton />
                </div>
            </div>
            <div className="main-banner-image--block">
                <div className="main-banner-image--wrapper">
                    {/*<img className="main-banner-image image" src={require('../../../assets/images/main-bunner-workers.png').default} alt=""/>*/}
                  <StaticImage  className="main-banner-image image" src={'../../../assets/images/main-bunner-workers.png'} alt="" />

                </div>
            </div>
        </div>
    )
}

export default MainBanner;