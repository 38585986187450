import React from "react";
import Text from "../../atoms/Text";
import {IconLink, Link} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";


const HeaderTop = () => {
    const {language, translate} = useLang();

    return (
        <div className="header-top">
            <div className="header-top--wrapper">
                <div className="header-top-text">
                    <Text text={`${translate.header.phoneText[language.name]}:`}/>
                </div>
                <div className="header-top-link">
                    <Link text="+48 573 339 716" link="tel:48573339716" />
                </div>
                <div className="header-top-icons">
                    <IconLink link="viber://chat?number=%2B48573339716" path={require('../../../assets/icons/viber.svg').default} alt="Viber контакт" />
                    <IconLink link="whatsapp://send?phone=+48573339716" path={require('../../../assets/icons/whatsapp.svg').default} alt="Whatsapp контакт"/>
                </div>
            </div>
        </div>
    )
}

export default HeaderTop;