
import React from "react";
import { RightArrow, LeftArrow, Text } from "../../atoms";
import { WorkerSlide1, WorkerSlide2, WorkerSlide3 } from "../../organisms";
import SlickSlider from "../../atoms/SlickSlider";
import { StaticImage } from "gatsby-plugin-image";

const SliderBlock = () => {
    const settings = {
        dots: false,
        infinite: true,
        // speed: 2000,
        // autoplaySpeed: 20000,
        slidesToShow: 1,
        // autoplay: true,
        // slidesToScroll: 1,
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />,
    };
    return (
        <div className="slider--block">
            <div className="container">
                <div className="worker-slider">
                    <SlickSlider settings={settings}>
                        <WorkerSlide1 index={1} title="Коментар від працівника" text="Працюю в фірмі команда 3 роки"  post="Петро, бригадир в Komanda" />
                        <WorkerSlide2 index={2} title="Коментар від працівника" text="Працюю в фірмі команда 3 роки"  post="Петро, бригадир в Komanda" />
                        <WorkerSlide3 index={3} title="Коментар від працівника" text="Працюю в фірмі команда 3 роки"  post="Петро, бригадир в Komanda" />
                    </SlickSlider>
                </div>
            </div>
        </div>

    )
}

export default SliderBlock;

/*
                       <div className="worker--wrapper">
                            <div className="worker-image">
                                <StaticImage className="image" src={'../../../assets/images/pracownik1.jpg'} alt=""/>
                            </div>
                            <div className="worker-info">
                                <div className="worker-text--wrapper">
                                    <div className="worker-count">
                                        <Text text={`${1}/3`}/>
                                    </div>
                                    <div className="worker-title">
                                        <Text text={'Коментар від працівника'} />
                                    </div>
                                    <div className="worker-text">
                                        <Text text={'Працюю в фірмі команда 3 роки'} />
                                    </div>
                                </div>


                                <div className="worker-post--wrapper">
                                    <div className="worker-post">
                                        <Text text={'Петро, бригадир в Komanda'} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="worker--wrapper">
                            <div className="worker-image">
                                <StaticImage className="image" src={'../../../assets/images/pracownik2.jpg'} alt=""/>
                            </div>
                            <div className="worker-info">
                                <div className="worker-text--wrapper">
                                    <div className="worker-count">
                                        <Text text={`${2}/3`}/>
                                    </div>
                                    <div className="worker-title">
                                        <Text text={'Коментар від працівника'} />
                                    </div>
                                    <div className="worker-text">
                                        <Text text={'Працюю в фірмі команда 3 роки'} />
                                    </div>
                                </div>


                                <div className="worker-post--wrapper">
                                    <div className="worker-post">
                                        <Text text={'Петро, бригадир в Komanda'} />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="worker--wrapper">
                            <div className="worker-image">
                                <StaticImage className="image" src={'../../../assets/images/pracownik3.jpg'} alt=""/>
                            </div>
                            <div className="worker-info">
                                <div className="worker-text--wrapper">
                                    <div className="worker-count">
                                        <Text text={`${3}/3`}/>
                                    </div>
                                    <div className="worker-title">
                                        <Text text={'Коментар від працівника'} />
                                    </div>
                                    <div className="worker-text">
                                        <Text text={'Працюю в фірмі команда 3 роки'} />
                                    </div>
                                </div>


                                <div className="worker-post--wrapper">
                                    <div className="worker-post">
                                        <Text text={'Петро, бригадир в Komanda'} />
                                    </div>

                                </div>
                            </div>
                        </div>
 */