import React from "react";
import Slider from "react-slick";

const SlickSlider = ({settings, children}) => {
    return (
        <div>
            <div className="main-category__wrapper">
                <Slider {...settings}>{children}</Slider>
            </div>
        </div>
    )
}

export default SlickSlider;