import React from "react";
import {Heading} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image";

const SecondAboutBlock = () => {
    const {language, translate} = useLang();
    return (
        <div className="container">
            <div className="about-us-header header-text">
                <Heading text={translate.about.title[language.name]} />
            </div>
            <div className="about-us--wrapper">
                <div className="about-us-text">
                    <p>
                        {translate.about.text[language.name][0]}
                    </p>
                    <p>
                        {translate.about.text[language.name][1]}
                    </p>
                    <p>
                        {translate.about.text[language.name][2]}
                    </p>
                </div>
                <div className="about-us-image--block">
                    <div className="about-us-image--wrapper">
                        <StaticImage className="about-us-image image" src={'../../../assets/images/building1.png'} alt="KomandaPL logo"/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SecondAboutBlock;