import React from "react";
import { LanguageButton} from "../../atoms";

const LanguageChange = () => {
    return (
        <div className="language-select">
            <LanguageButton />
        </div>

    )
}

export default LanguageChange;
