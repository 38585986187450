import React from "react";
import {Icon,  Logo} from "../../atoms";
import {Link} from "gatsby";
import {useLang} from "../../../helpers/context/LanguageProvider";

const FooterBottom = () => {
    const {language, translate} = useLang()

    return (
        <footer>
            <div className="container">
                <div className="footer--wrapper">
                    <div className="footer-column">
                        <div className="footer-logo">
                            <Logo isWhite={true} />
                        </div>

                        <div className="footer-address footer-address--main footer-title">Komanda Inwestycje Sp. z o.o.</div>
                        <a target="_blank" rel="noreferrer" rel="noreferrer" className="link" href="https://g.page/komandapl?share">
                            <div className="footer-address footer-address-item">Mazowiecka 74 05-077 Warszawa, Polska</div>
                        </a>
                        <div className="footer-media--block">
                            <div className="footer-media--wrapper">
                                <a className="link" rel="noreferrer" target="_blank" href="https://www.instagram.com/komanda.praca/">
                                    <div className="footer-media">
                                        <Icon path={require('../../../assets/icons/instagram.svg').default} />
                                    </div>
                                </a>
                                <a className="link" rel="noreferrer" target="_blank" href="https://g.page/komandapl?share">
                                    <div className="footer-media">
                                        <Icon path={require('../../../assets/icons/google.svg').default} />
                                    </div>
                                </a>
                                <a className="link" rel="noreferrer" target="_blank" href="https://www.facebook.com/komandapl/">
                                    <div className="footer-media">
                                        <Icon path={require('../../../assets/icons/facebook.svg').default} />
                                    </div>
                                </a>


                            </div>
                        </div>
                    </div>

                    <div className="footer-column">
                        <Link className="link" to={'/job-openings'}>
                            <span className="footer-item footer-title">{translate.header.items.vacancy[language.name]}</span>
                        </Link>
                        <Link className="link" to={'/about'}>
                            <span className="footer-item footer-title">{translate.header.items.about[language.name]}</span>
                        </Link>
                    </div>
                    <div className="footer-column">
                        <Link className="link" to={'/contacts'}>
                            <span className="footer-item footer-title">{translate.header.items.contacts[language.name]}</span>
                        </Link>
                        <div className="footer-link--wrapper">
                            <span className="footer-item footer-title">{translate.footer.items.main[language.name]}</span>
                            <div className="footer-link">
                                <Icon path={require('../../../assets/icons/link-icon.svg').default} />
                            </div>
                        </div>
                    </div>
                    <div className="footer-column footer-column--media">
                        <div className="footer-media--wrapper">
                            <a className="link" rel="noreferrer" target="_blank" href="https://www.instagram.com/komanda.praca/">
                                <div className="footer-media">
                                    <Icon path={require('../../../assets/icons/instagram.svg').default} />
                                </div>
                            </a>
                            <a className="link" rel="noreferrer" target="_blank" href="https://g.page/komandapl?share">
                                <div className="footer-media">
                                    <Icon path={require('../../../assets/icons/google.svg').default} />
                                </div>
                            </a>
                            <a className="link" rel="noreferrer" target="_blank" href="https://www.facebook.com/komandapl/">
                                <div className="footer-media">
                                    <Icon path={require('../../../assets/icons/facebook.svg').default} />
                                </div>
                            </a>


                        </div>
                        <div className="powered--wrapper">
                            <a className="link powered-link" href="mailto:mykhailopetrenkopl@gmail.com">
                                <div className="powered">
                                    <div className="powered-icon">
                                        <img className="image" src={require('../../../assets/icons/gears.svg').default} alt=""/>
                                    </div>
                                    <span className="powered-text">Powered by</span>
                                    <span>Mykhailo Petrenko</span>
                                </div>
                            </a>
                        </div>


                    </div>
                    <div className="powered--block">
                        <a className="link powered-link" href="mailto:mykhailopetrenkopl@gmail.com">
                            <div className="powered">
                                <div className="powered-icon">
                                    <img className="image" src={require('../../../assets/icons/gears.svg').default} alt=""/>
                                </div>
                                <span className="powered-text">Powered by</span>
                                <span>Mykhailo Petrenko</span>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default FooterBottom;