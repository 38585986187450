import React from "react";
import Icon from "../Icon";

const SearchInput = ({value, onChange, placeholder}) => {
    return (
        <div className="search-input--wrapper">
            <input onChange={(e) => onChange(e.target.value)} placeholder={placeholder} className="search-input" value={value} type="text"/>
            <div className="search-input-icon">
                <Icon path={require('../../../assets/icons/search-icon.svg').default} />
            </div>
        </div>
    )
}

export default SearchInput;