import React from "react";
import {HeaderLink, IconLink, Link, Logo, Sandwich} from "../../atoms";
import {LanguageChange} from "../../molecules";
import {useLang} from "../../../helpers/context/LanguageProvider";


const HeaderMobile = ({isOpen, setIsOpen}) => {
    const {language, translate} = useLang();

    return (
            <div style={{display: isOpen ? 'block' : 'none'}} className="header-mobile">
                <div className="header-mobile-logo--wrapper">
                    <div onClick={() => setIsOpen(false)}  className="header-mobile-logo">
                        <Logo />
                    </div>
                    <div className="header-mobile-menu">
                        <Sandwich setIsOpen={setIsOpen} isOpen={isOpen} />
                    </div>

                </div>

                <div className="header-mobile-links">
                    <div onClick={() => setIsOpen(false)}>
                        <HeaderLink text={translate.header.items.vacancy[language.name]} link={"/job-openings"} />
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <HeaderLink text={translate.header.items.about[language.name]} link={"/about"}/>
                    </div>
                    <div onClick={() => setIsOpen(false)}>
                        <HeaderLink text={translate.header.items.contacts[language.name]} link={"/contacts"}/>
                    </div>

                </div>
                <div className="header-mobile-contacts">
                    <div className="header-mobile-contacts--wrapper">
                        <div className="header-mobile-contacts-title">
                            {translate.header.phoneText[language.name]}
                        </div>
                        <div className="header-mobile-link">
                            <Link text="+48 573 339 716" link="tel:48573339716" />
                        </div>
                        <div className="header-mobile-icons">
                            <IconLink link="viber://chat?number=%2B48573339716" path={require('../../../assets/icons/viber.svg').default} alt="Viber контакт" />
                            <IconLink link="whatsapp://send?phone=+48573339716" path={require('../../../assets/icons/whatsapp.svg').default} alt="Whatsapp контакт"/>
                        </div>
                    </div>

                    <div className="header-mobile-select">
                        <LanguageChange />
                    </div>
                </div>

            </div>

    )
}

export default HeaderMobile;