import React, {useState} from "react";
import {HeaderBottom, HeaderMobile} from "../../organisms";


const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <header>
            <div className="container">
                <div>
                    <HeaderBottom setIsOpen={setIsOpen} isOpen={isOpen} />
                    <HeaderMobile isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
            </div>
        </header>
    )
}

export default Header;