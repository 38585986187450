import React, {useState} from "react";
import {Heading, SearchInput, Text} from "../../atoms";
import {ContactCard} from "../../molecules";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image";


const JobOpeningBlock = () => {
    const {language, translate} = useLang();
    const jobs = [
        {name: {  "PL": "Malarz - Tynkarz","UA": "Маляр - Штукатур","RU": "Маляр - Штукатур"}, text : "Malarz - Tynkarz Маляр - Штукатур" },
        {name: {"PL": "Wzmacniacz","UA": "Арматурщик","RU": "Арматурщик"}, text: "Wzmacniacz Арматурщик"},
        {name:{"PL": "Gipsiarz","UA": "Гіпсокартонщик","RU": "Гипсокартонщик"}, text: "Gipsiarz Гіпсокартонщик Гипсокартонщик"},
        {name: {"PL": "Murarz","UA": "Каменщік","RU": "Каменщик"}, text: "Murarz Каменщік Каменщик"}
    ]
    const [jobItems, setJobItems] = useState(jobs)
    const filterJobs = (text) => {
        setJobItems(jobs.filter((item) => item.text.toLowerCase().includes(text.toLowerCase())))
    }


    return (
        <div className="container">
            <div className="job-opening-header header-text">
                <Heading text={translate.vacancy.title[language.name]} />
            </div>
            <div className="job-opening-search--wrapper">
                <SearchInput placeholder={translate.vacancy.input[language.name]} onChange={filterJobs}/>
            </div>

            <div className="job-opening--wrapper">
                <div className="job-opening-info">
                    <div className="job-opening-search">
                        <Text text={translate.vacancy.info.search[language.name]} />
                        <SearchInput placeholder={translate.vacancy.input[language.name]} onChange={filterJobs}/>
                    </div>
                    <div className="job-opening-workers">
                        <div className="job-opening-workers-icons">
                            <div className="job-opening-workers-icon">👷</div>
                            <div className="job-opening-workers-icon job-opening-workers-icon--main">👷🏽‍♀️</div>
                            <div className="job-opening-workers-icon">👷</div>
                        </div>
                        {/*<img className="job-opening-workers-image" src={require('../../../assets/icons/workers.png').default} alt=""/>*/}
                        <div className="job-opening-workers-text">
                            <Text text={translate.vacancy.info.conditions.text[language.name]} />
                        </div>
                        <div className="job-opening-workers-button">{translate.vacancy.info.conditions.button[language.name]}</div>
                    </div>
                    <div className="job-opening-social">
                        <div className="job-opening-social-icon--wrapper">
                            <a className="link" rel="noreferrer" target="_blank" href="https://www.instagram.com/komanda.praca/">
                                <StaticImage placeholder="tracedSVG" className="job-opening-social-icon" src={'../../../assets/icons/instagram-white.svg'} alt="instagram"/>
                            </a>
                            <a className="link" rel="noreferrer" target="_blank" href="https://www.facebook.com/komandapl/">
                                <StaticImage placeholder="tracedSVG" className="job-opening-social-icon" src={'../../../assets/icons/facebook-white.svg'} alt="facebook"/>
                            </a>
                        </div>
                        <div className="job-opening-workers-text">
                            <Text text={translate.vacancy.info.media.text[language.name]} />
                        </div>
                        <div className="job-opening-social-button">{translate.vacancy.info.media.button[language.name]}</div>
                    </div>
                </div>
                <div className="job-opening-items">
                    <div className="empty-text" style={{display: jobItems.length === 0 ? 'block' : 'none'}}>
                        {translate.vacancy.empty[language.name]}
                    </div>
                    {jobItems?.map((item, index) => (
                        <div className="job-opening-item--wrapper" key={item.name + index}>
                              <ContactCard name={item?.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default JobOpeningBlock;