import React, { useEffect, useState } from "react";
import {Icon} from "../index";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image";

const LanguageButton = () => {
    const {language, changeLanguage} = useLang();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(prev => !prev)
    }
    const [selectedLanguage, setSelectedLanguage] = useState(language)

    useEffect(() => {
        setSelectedLanguage(language)
    }, [language])

    const handleChangeLanguage = (language) => {
        changeLanguage(language)
    }

    const getLanguageItem = (name, icon, data) => {
        return selectedLanguage.name !== name ? (
          <div onClick={() => handleChangeLanguage(name)} className={`language-select--wrapper`}>
              <div className="language-select-icon">
                  {data}
                  {/*<Icon path={require(`../../../assets/icons/${icon}`).default} />*/}
              </div>
              <div className="language-select-text">{name}</div>
          </div>
        ) : null
    }

    return (
        <div onClick={handleOpen} className={`language-select--block ${isOpen ? 'language-select--block-open' : ''}`}>
            <div className="language-select--wrapper">
                <div className="language-select-icon">
                    {selectedLanguage.data}
                    {/*<Icon path={require(`../../../assets/icons/${selectedLanguage.icon}`).default} />*/}
                </div>
                <div className="language-select-text">{selectedLanguage.name}</div>
                <div className={`language-select-arrow ${isOpen ? 'language-select-arrow--rotated' : ''}`}>
                    <Icon path={require('../../../assets/icons/arrow-down.svg').default} />
                </div>
            </div>
            <div className={`language-select-items ${isOpen ? "" : "language-select-items--close"}`}>
                {/*{getLanguageItemUA()}*/}
                {/*{getLanguageItemPL()}*/}
                {/*{getLanguageItemRU()}*/}
                {getLanguageItem("UA", "ukraine.svg", "🇺🇦")}
                {getLanguageItem("PL", "poland.svg", "🇵🇱")}
                {getLanguageItem("RU", "lgbt.png", "🇷🇺")}
                {/*<div onClick={() => handleChangeLanguage('UA')} className={`language-select--wrapper ${selectedLanguage.name === 'UA' ? 'language-select--wrapper--selected' : ''}`}>*/}
                {/*    <div className="language-select-icon">*/}
                {/*        <Icon path={require(`../../../assets/icons/ukraine.svg`).default} />*/}
                {/*    </div>*/}
                {/*    <div className="language-select-text">UA</div>*/}
                {/*</div>*/}
                {/*<div onClick={() => handleChangeLanguage('PL')} className={`language-select--wrapper ${selectedLanguage.name === 'PL' ? 'language-select--wrapper--selected' : ''}`}>*/}
                {/*    <div className="language-select-icon">*/}
                {/*        <Icon path={require(`../../../assets/icons/poland.svg`).default} />*/}
                {/*    </div>*/}
                {/*    <div className="language-select-text">PL</div>*/}
                {/*</div>*/}
                {/*<div onClick={() => handleChangeLanguage('RU')} className={`language-select--wrapper ${selectedLanguage.name === 'RU' ? 'language-select--wrapper--selected' : ''}`}>*/}
                {/*    <div className="language-select-icon">*/}
                {/*        <Icon path={require(`../../../assets/icons/lgbt.png`).default} />*/}
                {/*    </div>*/}
                {/*    <div className="language-select-text">RU</div>*/}
                {/*</div>*/}
            </div>

        </div>
    )
}

export default LanguageButton;

/*
        <div onClick={() => handleChangeLanguage()} className="language-select-icons">
            <Icon path={require(`../../../assets/icons/${language.icons}`).default} />
        </div>
 */