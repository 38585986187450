import React from "react";
import {HeaderLink, Logo, Sandwich} from "../../atoms";
import {LanguageChange} from "../../molecules";
import {useLang} from "../../../helpers/context/LanguageProvider";
import {HeaderTop} from "../index";

const HeaderBottom = ({setIsOpen, isOpen}) => {
    const {language, translate} = useLang();
    return (
        <div className="header-bottom">
            <div className="header-bottom-items--wrapper">
                <div className="header-bottom-logo">
                    <Logo />
                </div>
                <div className="header-bottom-items">
                    <HeaderLink text={translate.header.items.vacancy[language.name]} link={"/job-openings"} />
                    <HeaderLink text={translate.header.items.about[language.name]} link={"/about"}/>
                    <HeaderLink text={translate.header.items.contacts[language.name]} link={"/contacts"}/>
                </div>
            </div>
            <div className="header-bottom-right">
                <HeaderTop />
                <div>
                    <LanguageChange />
                </div>
            </div>
            <Sandwich setIsOpen={setIsOpen} isOpen={isOpen} />
        </div>
    )
}

export default HeaderBottom;