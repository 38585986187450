import React from "react";
import {Heading} from "../../atoms";
import { NecessaryItem1, NecessaryItem2, NecessaryItem3 } from "../../molecules";
import {useLang} from "../../../helpers/context/LanguageProvider";

const RealizationBlock = () => {
    const {language, translate} = useLang();

    return (
        <div className="container">
            <div className="realization">
                <div className="realization-title ">
                    <Heading text={translate.about.realization.title[language.name]} />
                </div>
                <div className="realization-items">
                    <NecessaryItem1 title={"Royal Tulip"} text={translate.about.realization.tulip[language.name]}  />
                    <NecessaryItem2 title={"Stadion LKS"} text={translate.about.realization.lks[language.name]}  />
                    <NecessaryItem3 title={"Biedronka"} text={translate.about.realization.biedronka[language.name]}  />
                </div>
            </div>
        </div>
    )
}

export default RealizationBlock;