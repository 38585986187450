import React from "react";
import { Heading} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";

const ContactCard = ({name}) => {
    const {language, translate} = useLang();
    return (
        <div className='contact-card'>
            <div className="contact-card-main">
                <div className="contact-card-title">
                    <Heading text={name[language.name]} />
                </div>
                <div className="contact-card-info">
                    <div className="contact-card-text contact-card-item--wrapper">
                        <img className="contact-card-icon" src={require('../../../assets/icons/location.svg').default} alt=""/>
                        {translate.vacancy.item.country[language.name]}
                    </div>
                    <div className="contact-card-text contact-card-item--wrapper">
                        <img className="contact-card-icon" src={require('../../../assets/icons/suitcase-icon.svg').default} alt=""/>
                        {translate.vacancy.item.rate[language.name]}
                    </div>
                </div>
                <div className="contact-card-benefit--wrapper">
                    <div style={{backgroundColor: "#E2D3FF", color: "#30008B"}} className="contact-card-benefit">{translate.vacancy.item.benefits.house[language.name]}</div>
                    <div style={{backgroundColor: "#FFE9D3", color: "#605900"}} className="contact-card-benefit">{translate.vacancy.item.benefits.language[language.name]}</div>
                    <div style={{backgroundColor: "#FFD3D3", color: "#AD0000"}} className="contact-card-benefit">{translate.vacancy.item.benefits.work[language.name]}</div>
                    <div style={{backgroundColor: "#9AFFC5", color: "#00811E"}} className="contact-card-benefit">{translate.vacancy.item.benefits.upfront[language.name]}</div>
                </div>
            </div>
            <div className="contact-card-button--wrapper">
                <div className="contact-card-button">Оплата за години</div>
                <div className="contact-card-button">Оплата за метри<span className="contact-card-button-text">2</span></div>
            </div>
        </div>
    )
}

export default ContactCard;