import React from "react";
import { Heading, Text} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image";

const ContactsBlock = () => {
    const {language, translate} = useLang();

    return (
        <div>
            <div className="contacts-header">
                <Heading text={translate.contact.title[language.name]} />
            </div>
            <div className="container">
                <div className="contacts">
                    <div className="contacts-text--wrapper">
                        <Text text={translate.contact.phone.main[language.name]} />
                        <a className="link contacts-link-text" href="tel:+48573339716"><Text text="+48 573 339 716" /></a>
                    </div>
                    <div className="contacts-text--wrapper">
                        <Text text={translate.contact.phone.other[language.name]} />
                        <a className="link contacts-link-text" href="tel:+48573479584"><Text text="+48 573 479 584" /></a>
                    </div>
                    <div className="contacts-items">
                        <a className="link contacts-link" rel="noreferrer" href="viber://chat?number=%2B48573339716" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/viber-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#EACEF9', color: '#B95AE9'}} className="contacts-text">Viber</div>
                        </a>
                        <a className="link contacts-link" rel="noreferrer" href="whatsapp://send?phone=+48573339716" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/whatsapp-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#C0F7C9', color: '#2BB741'}} className="contacts-text">WhatsApp</div>
                        </a>
                        <a className="link contacts-link" href="" rel="noreferrer" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/telegram-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#C5E6F8', color: '#199BE4'}} className="contacts-text">Telegram</div>
                        </a>
                        <a className="link contacts-link" href="" rel="noreferrer" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/instagram-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#F6E8D1', color: '#D9A549'}} className="contacts-text">Instagram</div>
                        </a>
                        <a className="link contacts-link" href="" rel="noreferrer" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage  placeholder="tracedSVG" className="image" src={'../../../assets/icons/facebook-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#C5DDFC', color: '#1977F3'}} className="contacts-text">Facebook</div>
                        </a>
                        <a className="link contacts-link" href="" rel="noreferrer" target='_blank'>
                            <div className="contacts-image">
                                <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/google-icon.svg'} alt=""/>
                            </div>
                            <div style={{backgroundColor: '#F6D1D1', color: '#D94949'}} className="contacts-text">Google</div>
                        </a>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContactsBlock;