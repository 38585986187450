import React from "react";

const IconLink = ({path, alt, link}) => {
    return (
        <a className="link" href={link}>
            <div className="icon--wrapper">
                <img  className="image icon" src={path} alt={alt}/>
            </div>
        </a>
    )
}

export default IconLink;