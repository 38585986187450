import React from "react";

const LeftArrow = ({onClick}) => {
    return (
        <div className="slider-arrow slider-arrow--left" onClick={onClick}>
            <img className="image" src={require('../../../assets/icons/arrow-left.svg').default} alt=""/>
        </div>
    )
}

export default LeftArrow;