import React from "react";
import {Text} from "../index";
import {Link} from "gatsby";
import {useLang} from "../../../helpers/context/LanguageProvider";


const VacancyButton = () => {
    const {language, translate} = useLang();
    return (
        <Link className="link" to={'/job-openings'}>
            <div className="vacancy-button">
                <Text text={translate.vacancy.title[language.name]} />
            </div>
        </Link>

    )
}

export default VacancyButton;