import React from "react";
import {Link} from "gatsby";
import {useLang} from "../../../helpers/context/LanguageProvider";

const ContactButton = () => {
    const {language, translate} = useLang()
    return (
        <Link className="link" to={'/contacts'}>
            <div className="contact-button">
                {translate.contact.title[language.name]}
            </div>
        </Link>

    )
}

export default ContactButton;
