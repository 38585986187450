import React from "react";
import {Link} from "gatsby";

const HeaderLink = ({text, link}) => {
    return (
        <div className="nav-link">
            <Link className={"link"} to={link}>
                {text}
            </Link>
        </div>

    )
}

export default HeaderLink;