import React from "react";

const Icon = ({path, alt}) => {
    return (
            <div>
                <img  className="image icon" src={path} alt={alt}/>
            </div>
    )
}

export default Icon;