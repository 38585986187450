import React from "react";
import {ContactButton, Heading, Text} from "../../atoms";
import {useLang} from "../../../helpers/context/LanguageProvider";
import { StaticImage } from "gatsby-plugin-image";


const FooterTop = ({isShow=true}) => {
    const {language, translate} = useLang()
    return (
        <div style={{display: isShow ? 'block' : 'none'}} className="footer-top--wrapper">
            <div className="container footer-top">
                <div>
                    <div className="footer-top-title">
                        <Heading text={translate.footer.top.title[language.name]} />
                    </div>
                    <div className="footer-top-text">
                        <Text text={translate.footer.top.text[language.name]} />
                    </div>
                    <ContactButton />
                </div>
                <div className="footer-top-image">
                    <StaticImage placeholder="tracedSVG" className="image" src={'../../../assets/icons/phone.png'} alt=""/>
                </div>
                {/*<ContactButton number={"+48 573 339 716"} tel={"48573339716"}  />*/}
            </div>
        </div>
    )
}

export default FooterTop;